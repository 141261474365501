import axios from 'axios';

export const FETCH_PRINCIPAL_REQUEST = 'FETCH_PRINCIPAL_REQUEST';
export const FETCH_PRINCIPAL_SUCCESS = 'FETCH_PRINCIPAL_SUCCESS';
export const FETCH_PRINCIPAL_FAILURE = 'FETCH_PRINCIPAL_FAILURE';

export const CLEAR_STORE = "CLEAR_STORE"

export const clearStoreAction = () => ({
    type: 'CLEAR_STORE',
});

export const fetchPrincipals = () => async (dispatch) => {
    dispatch({ type: FETCH_PRINCIPAL_REQUEST });
    console.log("lnklbnkj")
    try {
        // const token = await axios.request({
        //     baseURL: 'https://apitest.fincentive.co' + '/api/v1/auth/login', // Base URL for your API
        //     timeout: 10000000, // Timeout in milliseconds
        //     headers: {
        //         'Content-Type': 'application/json',
        //     }, // Default headers
        //     data: {
        //         "email": "test2@gmail.com",
        //         "password": "password@28"
        //     },
        //     method: 'POST'
        // }).then(response => {
        //     return response.data.tokens.access.token;
        // })

        const token = localStorage.getItem('accessToken')
        

        const response = await axios.request({
            baseURL: window.env.API_URL + '/v1/programs/principals/?page=1&limit=20', // Base URL for your API
            timeout: 10000000, // Timeout in milliseconds
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,

            },
            method: 'GET'
        })

        dispatch({ type: FETCH_PRINCIPAL_SUCCESS, payload: response.data });
    } catch (error) {
        console.log('kjbkbkyuy', window.env)
        dispatch({ type: FETCH_PRINCIPAL_FAILURE, error: error.message });
    }
};

