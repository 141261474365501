import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchTenants } from "../../redux/tenantActions";
import '../pages.scss';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import { IoMdArrowBack } from "react-icons/io";
import { IoIosSearch } from "react-icons/io";
import { useLocation } from 'react-router-dom';
import PlaceholderImage from "../../assests/placeholder.jpg";



function TenantPage() {
    const location = useLocation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { tenant, loading, error } = useSelector((state) => state.tenant);
    const { program_name, program_id, principal_name, principal_id } = location.state || {};

    const [searchTerm, setSearchTerm] = useState("");
    const [isSearchVisible, setSearchVisible] = useState(false); // State to manage search visibility

    useEffect(() => {
        dispatch(fetchTenants(id));
    }, [dispatch, id]);

    const handleClick = async (tenantId, name) => {
        try {
            navigate('/tenant/' + tenantId, {
                state: {
                    program_name,
                    program_id,
                    principal_name,
                    principal_id,
                    tenant_id: tenantId,
                    tenant_name: name
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };
 
    // Filter tenants based on the search term
    const filteredTenants = tenant?.tenants ? tenant.tenants.filter(item =>{       
        return item["storeCoordinator"]? item["storeCoordinator"].toLowerCase().includes(searchTerm.toLowerCase()): null ||
        item["phone"]?item["phone"].toString().includes(searchTerm): null || // Convert phone to string for comparison
        item["tenantId"]?item["tenantId"].toString().includes(searchTerm) : null // Convert tenantId to string for comparison
}) : [];

    const toggleSearchVisibility = () => {
        setSearchVisible(prev => !prev);
        if (isSearchVisible) {
            setSearchTerm(""); // Clear search term when hiding
        }
    };

    return (
        <div>
            {loading && (
                <div className="loading-overlay">
                    <Spinner animation="border" variant="primary" />
                </div>
            )}

            {tenant && (
                <div>
                    <div className="navbar-header">
                        <IoMdArrowBack onClick={() => navigate("/principal/" + principal_id, {
                            state: {
                                program_name,
                                program_id,
                                principal_name,
                                principal_id,
                            }
                        })} />

                        <div>
                            {!isSearchVisible && (<>Outlets  <div className="subheading">{program_name}</div></>)}

                        </div>
                        <div className="navbar-search">
                            <input
                                className={isSearchVisible ? "active" : ""}
                                type="text"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder="Search"
                            />
                            <IoIosSearch
                                onClick={toggleSearchVisibility} // Toggle search visibility on icon click
                            />
                        </div>

                    </div>

                    <div className="navbar-page">
                        {filteredTenants.map((item, index) => (
                            <div key={index} onClick={() => handleClick(item["tenantId"], item["tenantName"])}>
                                <Card>
                                    <Card.Body>
                                        <div className="header">
                                            {item["storeLogo"] ? <Image className="logo-img" src={item["storeLogo"] + `?t=${new Date().getTime()}`} alt="Store Logo" /> : null}
                                            <div>{item["tenantName"]}</div>
                                        </div>
                                    </Card.Body>
                                    <Card.Img variant="top" src={item["storeImage"] ? item["storeImage"] + `?t=${new Date().getTime()}` : PlaceholderImage} alt="Store" />
                                    <Card.Body>
                                        <div className="title">Store ID: {item["tenantId"]}</div>
                                        <div className="body">{item["address"]}</div>
                                        <div className="body">{item["city"]}, {item["state"]}</div>
                                        <div className="body">{item["storeCoordinator"]}</div>
                                        <div className="body">{item["phone"]}</div>
                                        <div className="card-btn">
                                            <button  className={item["latitude"] && item["longitude"] ? "" : "disabled-btn"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation(); 
                                                    if (item["latitude"] && item["longitude"]) {
                                                        window.open(`https://www.google.com/maps?q=${item["latitude"]},${item["longitude"]}`, "_blank");
                                                    }
                                                }
                                                }>Directions</button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default TenantPage;
