import axios from 'axios';

// Action Types
export const FETCH_VPA_REQUEST = 'FETCH_VPA_REQUEST';
export const FETCH_VPA_SUCCESS = 'FETCH_VPA_SUCCESS';
export const FETCH_VPA_FAILURE = 'FETCH_VPA_FAILURE';

export const UPDATE_VPA_DETAIL_REQUEST = 'UPDATE_VPA_DETAIL_REQUEST';
export const UPDATE_VPA_DETAIL_SUCCESS = 'UPDATE_VPA_DETAIL_SUCCESS';
export const UPDATE_VPA_DETAIL_FAILURE = 'UPDATE_VPA_DETAIL_FAILURE';

export const DELETE_VPA_REQUEST = 'DELETE_VPA_REQUEST';
export const DELETE_VPA_SUCCESS = 'DELETE_VPA_SUCCESS';
export const DELETE_VPA_FAILURE = 'DELETE_VPA_FAILURE';

export const CLEAR_STORE = 'CLEAR_STORE';

export const clearStoreAction = () => ({
    type: CLEAR_STORE,
});

// Fetch VPAs
export const fetchVPAs = (tenantId) => async (dispatch) => {
    dispatch({ type: FETCH_VPA_REQUEST });
    try {
        const token = localStorage.getItem('accessToken')

        const response = await axios.request({
            baseURL: window.env.API_URL + `/v1/programs/mappings?tenantId=${tenantId}`,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            method: 'GET'
        });

        dispatch({ type: FETCH_VPA_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: FETCH_VPA_FAILURE, error: error.message });
    }
};

// Update a VPA
export const addVPA = (file, vpa, providerName, status, merchantName, id) => async (dispatch) => {
    dispatch({ type: UPDATE_VPA_DETAIL_REQUEST });
    try {
        const token = localStorage.getItem('accessToken')
        const formData = new FormData();

        // Append VPA data to form data
        formData.append('vpa', vpa);
        formData.append('providerName', providerName);
        formData.append('status', status);
        formData.append('merchantNameFromVpa', merchantName);
        // Append file if present
        if (file) {
            console.log(file)
            formData.append('storeImage.file', file);
            console.log(formData)
        }

        const response = await axios.request({
            baseURL: window.env.API_URL + `/v1/programs/vpas`,
            timeout: 10000,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            data: formData,
            method: 'POST'
        });
       

        if (response.status != 201) {
            const errorData = await response.json();
            console.error('Error creating VPA:', errorData.message);
            alert('Error creating VPA: ' + errorData.message);
            dispatch({ type: UPDATE_VPA_DETAIL_FAILURE, error: errorData.message });
        }

        const finalresponse = await axios.request({
            baseURL: window.env.API_URL + `/v1/programs/mappings`,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            data: JSON.stringify({
                tenantId: id,
                vpaId: response.data.vpaId
            }),
            method: 'POST'
        });

        if (finalresponse.status == 201) {

            dispatch({ type: UPDATE_VPA_DETAIL_SUCCESS, payload: response.data });
            return Promise.resolve()
        }
        else{
            const errorData = await finalresponse.data;
            console.error('Error creating VPA:', errorData);
            alert('Error creating VPA: ' + errorData);
            dispatch({ type: UPDATE_VPA_DETAIL_FAILURE, error: errorData });
        }

    } catch (error) {
        dispatch({ type: UPDATE_VPA_DETAIL_FAILURE, error: error.message });
        return Promise.reject();
    }
};

// Delete a VPA
export const deleteVPA = (vpaId) => async (dispatch) => {
    dispatch({ type: DELETE_VPA_REQUEST });
    try {
        const token = localStorage.getItem('accessToken')

        const response = await axios.request({
            baseURL: window.env.API_URL + `/v1/programs/vpas/${vpaId}`,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            method: 'DELETE'
        });

        if (response.status == 200) {
            dispatch({ type: DELETE_VPA_SUCCESS, payload: vpaId });
            return Promise.resolve()
        }
        else{
            const errorData = await response.data;
            console.error('Error Deleting VPA:', errorData);
            alert('Error creating VPA: ' + errorData);
            dispatch({ type: DELETE_VPA_FAILURE, error: errorData });
        }

    } catch (error) {
        dispatch({ type: DELETE_VPA_FAILURE, error: error.message });
    }
};
