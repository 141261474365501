import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchPrincipals } from "../../redux/principalActions";
import '../pages.scss';
import { IoMdArrowDropright, IoMdLogOut } from "react-icons/io";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoIosSearch } from "react-icons/io";
import { IoLogOutOutline } from "react-icons/io5";


function PrincipalPage({ setAuth }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { principal, loading, error } = useSelector((state) => state.principal);

    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [isSearchVisible, setSearchVisible] = useState(false); // State to manage search visibility
    const sidebarRef = useRef(null);

    console.log("lnklnlkn")
    useEffect(() => {
        dispatch(fetchPrincipals());
    }, [dispatch]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setSidebarOpen(false);
                setSearchVisible(false); // Close search when clicking outside
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [sidebarRef]);

    const handleClick = async (id, name) => {
        try {
            navigate('/principal/' + id, {
                state:{
                    principal_name: name,
                    principal_id: id
                }

            });
        } catch (error) {
            console.error(error);
        }
    };

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        setAuth(false);
        navigate('/');
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredPrincipals = principal ? principal["principals"]["rows"].filter(item =>
        item["name"].toLowerCase().includes(searchTerm.toLowerCase())
    ) : [];

    const toggleSearchVisibility = () => {
        setSearchVisible(prev => !prev);
        if (isSearchVisible) {
            setSearchTerm(""); // Clear search term when hiding
        }
    };

    return (
        <div>
            {loading && (
                <div className="loading-overlay">
                    <Spinner animation="border" variant="primary" />
                </div>
            )}

            {principal && (
                <div>
                    <div className="navbar-header">
                        <RxHamburgerMenu onClick={toggleSidebar} />
                        {!isSearchVisible && (<>Principals</>)}
                        <div className="navbar-search">
                            <input
                                className={isSearchVisible ? "active" : ""}
                                type="text"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder="Search"
                            />
                            <IoIosSearch
                                onClick={toggleSearchVisibility} // Toggle search visibility on icon click
                            />
                        </div>

                    </div>


                    {isSidebarOpen && (
                        <div className="sidebar" ref={sidebarRef}>
                            <ul>
                                <li onClick={handleLogout}>
                                    <div style={{ fontSize: '12px', fontWeight: "bold" }}>
                                        <IoLogOutOutline style={{ marginRight: '8px', width: '24px', height: '24px', fontWeight: 'bold' }} />
                                        Logout
                                    </div>
                                </li>
                                <li>
                                    <div style={{ fontSize: '12px', fontWeight: "bold" }}>
                                        <div style={{ fontSize: '12px', fontWeight: "bold" }}>Help Center</div>
                                        +91 9089089089
                                    </div>
                                </li>
                            </ul>
                        </div>
                    )}

                    <div className="navbar-page">
                        {filteredPrincipals.map((item, index) => (
                            <div key={index} className="list-item" onClick={() => handleClick(item["principalId"], item["name"])}>
                                <div>{item["name"]}</div>
                                <IoMdArrowDropright />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default PrincipalPage;
